<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>¡A solicitar un préstamo fácil</h1>
          <h1>y seguro en cualquier lugar</h1>
          <h1>y momento!</h1>
          <h4>Te transferimos el dinero en menos de 5 minutos. Sin aval y sin </h4>
          <h4>papeleos.Con total transparencia y seguridad.</h4>
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <h3>El mejor préstamo en línea</h3>
      <div class="inner2_item flex jc-sb">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <img class="bg" :src="item.img" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="left_item">
        <div class="wbg">
          <h1>Nuestra ventaja</h1>
        </div>
        <div class="item_info">
          <!-- <img class="info_img1" src="@/assets/images/loanImg/icon_info.png" alt=""> -->
          <div class="info_tips">
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick1.png" alt="">Créditos rápidos de hasta $ 50,000</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick2.png" alt="">Úsalo con confianza durante 270 días</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick3.png" alt="">Te transferimos el dinero en menos de 5 mins</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick4.png" alt="">Tu préstamo online rápido, sin aval y sin papeleos</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick5.png" alt="">Sin letra pequeña, con total transparencia y seguridad</p>
          </div>
          <!-- <img class="info_img2" src="@/assets/images/loanImg/icon_info2.png" alt=""> -->
        </div>
        <!-- <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div> -->
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner4 flex jc-sb ai-c">
      <img class="bg" src="@/assets/images/inner3_bg.png">
      <div>
        <h1>Primera opción para dinero urgente!</h1>
        <p>Solicita un préstamo y se te depositará enseguida</p>
      </div>
      <div class="item_download" @click="downloadApp()">
        <img src="@/assets/images/Google.png" alt="">
      </div>
    </div>
    <div class="inner inner5">
      <h3>¿Cómo obtener un préstamo?</h3>
      <div class="inner2_item flex jc-sb">
        <div v-for="(item,index) in inner_5items" :key="index" class="content">
          <img class="bg" :src="item.img" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector1" class="inner inner6">
      <h3>Nuestros usuarios quieren compartir con usted</h3>
      <div class="flex al-c jc-c">
        <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item flex fd-c jc-sb">
                <div class="flex jc-c ai-c">
                  <img class="tx" :src="item.img">
                  <div>
                    <h1>{{ item.name }}</h1>
                    <h2>{{ item.txt }}</h2>
                  </div>
                </div>
                <!-- <div class="flex stars">
                  <img v-for="index in 5" :key="index" src="@/assets/images/loanImg/star.png" >
                </div> -->
                <div>
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: '100% seguro y fiable', txt2: 'Utilizamos las más avanzadas herramientas \n de encriptación de datos para asegurar \n tu privacidad', img: require('@/assets/images/itemList_01.png'), img2: require('@/assets/images/itemList_04.png') },
        { txt: 'Más conveniente', txt2: 'Todo digital y sin papeleos. ¡Puedes hacer \n tu solicitud en línea en cualquier momento!', img: require('@/assets/images/itemList_02.png'), img2: require('@/assets/images/itemList_05.png') },
        { txt: 'Servicio de calidad', txt2: 'Ofrece los mejores servicios de prestamo \n al cliente, tasas de interes bajas y sin \n cargos ocultos.', img: require('@/assets/images/itemList_03.png'), img2: require('@/assets/images/itemList_06.png') }
      ],
      leftItemList2: [
        { title: 'What can you get?', list2: [{ img: require('@/assets/images/list01.png'), txt: 'From 1,000 ₱ up to 50,000 ₱' }, { img: require('@/assets/images/list02.png'), txt: 'Repayment Term：0-180 days' }, { img: require('@/assets/images/list03.png'), txt: 'Annual interest rate：10%-35%' }] },
        { title: 'Who is eligible to get a loan?', list2: [{ img: require('@/assets/images/list04.png'), txt: 'Age 18-60 years' }, { img: require('@/assets/images/list05.png'), txt: 'Philippines citizen' }, { img: require('@/assets/images/list06.png'), txt: 'Employed or Business-owner' }] }
      ],
      inner5ItemLeftList: [
        { title: 'Online service', txt: 'You can find online services in ', txt2: 'the PastaBasta app.', img: require('@/assets/images/ng_1.png') },
        { title: 'E-mail', txt: 'tigercreditapp@gmail.com', img: require('@/assets/images/ng_2.png') },
        { title: 'Customer service hours', txt: '9AM-6PM', img: require('@/assets/images/ng_3.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', name: 'Roldan Mascorro', txt: 'Autónomos', txt2: '“Muy bueno, obtuve un pequeño préstamo \n de $ 3,000 y fue muy rápido así que lo \n califico muy bien y seguiré usando este \n servicio, fue genial y le di 5 estrellas, \n muchas gracias por su bendición.  “', img: require('@/assets/images/Product01.png') },
        { id: '2', name: 'Luiza Sedillo', txt: 'Empleados', txt2: '“Me ha gustado porque ha sido rápido y \n fácil. La forma de solicitar el préstamo y \n los pasos posteriores fueron rápidos y \n claros. Estoy muy contento con el proceso, \n muy contento.”', img: require('@/assets/images/Product02.png') },
        { id: '3', name: 'Cristiano Canal', txt: 'Pintor', txt2: '“PastaBasta es una excelente institución \n financiera y son muy flexibles y \n convenientes para sus clientes en \n comparación con otras aplicaciones. Estoy \n muy contento y agradecido por su ayuda, \n gracias！”', img: require('@/assets/images/Product03.png') },
        { id: '4', name: 'Alhertina Rayon', txt: 'Empresarios', txt2: '“Me gusta PastaBasta porque es muy \n rápido en darme el dinero que necesito \n y si tienes alguna duda se comunican \n inmediatamente. Por supuesto, lo \n recomiendo.”', img: require('@/assets/images/Product04.png') }
      ],
      visibleItemCount: 3,
      currentIndex: 0,
      inner_5items: [
        { txt: 'Registro', txt2: 'Regístrese después de descargar \n la aplicación', img: require('@/assets/images/itemList_04.png') },
        { txt: 'Complete la información', txt2: 'complete la informacionsimple que \n la App solicita', img: require('@/assets/images/itemList_05.png') },
        { txt: 'Revisión', txt2: 'Revisamos tu solicitud en linea las \n 24 horas del día', img: require('@/assets/images/itemList_06.png') },
        { txt: 'Obtenga un préstamo', txt2: 'introduzca su numero de tarjeta \n bancaria correct amente para \n garantizar un crédito exitoso', img: require('@/assets/images/itemList_07.png') }
      ],
      inner_3items: [
        { img: require('@/assets/images/list07.png'), txt: 'Puede elegir la cantidad y \n el límite de tiempo' },
        { img: require('@/assets/images/list10.png'), txt: 'Puede solicitar un préstamo \n en cualquier momento' },
        { img: require('@/assets/images/list08.png'), txt: 'Preste dinero todo el día los \n 365 días, no se preocupe por los \n fines de semana y feriados' },
        { img: require('@/assets/images/list11.png'), txt: 'Solo necesitas enviar una solicitud, \n te atenderemos durante todo el \n proceso' }
      ],
      inner6ItemLeftList: [
        { title: 'apexlendinghelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: '78 Ogunnusi Road, Ojodu Berger, Lagos State.', img: require('@/assets/images/address.png') },
        { title: '9AM-5PM', img: require('@/assets/images/time.png') }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },

    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=pastabasta.rapido.prestamo', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top{
  min-width: 1200px;
  background: #fff;
  white-space: pre-line;
  .inner{
    width: 100%;
    padding:60px calc((100% - 1100px) / 2);
    margin: 0 auto;
  }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      padding:60px calc((100% - 1100px) / 2);
      background: url("~@/assets/images/loanImg/inner6_bg.png") no-repeat;
      background-size: 100% 100%;
      height: 600px;
      display: flex;
      justify-content: space-between;
      .left_item{
        .wbg{
          padding: 40px 25px 30px;
          background: #FFFFFF99;
          border-radius: 20px;
        }
        box-sizing: border-box;
        border-radius: 10px;
        h1{
          font-size: 45px;
          color: black;
          font-weight: 700;
          // margin-bottom: 10px;
          line-height: 60px;
          span{
            color: #00BB53;
          }
        }
        h4{
          font-size: 16px;
          line-height: 30px;
          color: #666666;
          font-weight: normal;
          // margin: 0px 0;
        }
        & h4:nth-of-type(1){
          margin-top: 40px;
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 40px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          cursor: pointer;
          margin-top: 70px;
          color: white;
          border-radius: 5px;
          img{
            width: 190px;
            height: 60px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        img{
          height: 376px;
          margin-right: 30px;
        }
      }
    }
  .inner3{
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    padding:60px calc((100% - 1100px) / 2);
    background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat right #EAF1FF;
    background-size: 45% 100%;
    height: 550px;
    display: flex;
    justify-content: space-between;
    .left_item{
      box-sizing: border-box;
      border-radius: 10px;
      h1{
        font-size: 35px;
        color: #333333;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 70px;
        span{
          color: #00BB53;
        }
      }
      h4{
        font-size: 16px;
        line-height: 30px;
        color: #333333;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1){
        margin-top: 40px;
      }
      .item_info{
        margin: 20px 0;
        display: flex;
        height: 300px;
        .info_img1{
          width: 40px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 16px;
            color: #333333;
            align-items: center;
            img{
              margin-right: 10px;
            }
          }
        }
        .info_img2{
          width: 135px;
          height: 135px;
        }
      }
      .item_download{
        // padding-left: 22px;
        cursor: pointer;
        margin-top: 40px;
        color: white;
        border-radius: 5px;
        img{
          width: 190px;
          height: 60px;
          margin-right: 5px;
        }
      }
    }
    .right_item{
      img{
        height: 376px;
        margin-right: 30px;
      }
    }
  }
  .inner2{
    position: relative;
    padding:90px calc((100% - 1100px) / 2) 60px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 32px;
      text-align: left;
      margin-bottom: 60px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      border: 1px solid #E2E2E2;
      width: 31%;
      text-align: left;
      color:black;
      padding: 0 0 30px 0;
      border-radius: 20px;
      .item_info{
        margin: 30px 30px 0;
      }
      .bg{
        width: 100%;
      }
      .bg2{
        display: block;
        width: 45%;
        margin: -90px auto 0;
      }
      h1{
        font-size: 20px;
        // line-height: 50px;
        margin:0 0 15px 0;
        color: #4D82DE;
      }
      p{
        color: #666666;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
  .inner4{
    position: relative;
    padding:0px calc((100% - 1350px) / 2);
    background:url('');
    background: #3984E5;
    color: white;
    .bg{
      width: 420px;
      height: 250px;
      margin-right: 10px;
    }
    div{
      flex: 1;
    }
    h1{
      line-height: 60px;
    }
    .item_download{
      // padding-left: 22px;
      cursor: pointer;
      margin-top: 40px;
      color: white;
      border-radius: 5px;
      flex: 0.4;
      img{
        width: 190px;
        height: 60px;
        margin-right: 5px;
      }
    }
  }
  .inner5{
    position: relative;
    padding:90px calc((100% - 1100px) / 2) 80px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 32px;
      text-align: left;
      margin-bottom: 60px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      background: #F2F4F8;
      border: 1px solid #E2E2E2;
      width: 24%;
      text-align: center;
      color:black;
      padding: 30px 0 30px 0;
      .item_info{
        margin: 30px 0px 0;
      }
      .bg{
        width:100px;
        margin: 0 auto;
        display: block;
      }
      .bg2{
        display: block;
        width: 45%;
        margin: -90px auto 0;
      }
      h1{
        font-size:18px;
        // line-height: 50px;
        margin:0 0 15px 0;
      }
      p{
        color: #666666;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
  .inner6{
    background: #3984E5;
    padding: 50px calc((100% - 1300px) / 2) 60px;
    h3{
      font-size: 30px;
      color: white;
      text-align: left;
      // margin-bottom: 20px;
      padding-left: 70px;
      position: relative;
      margin-bottom: 60px;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #00BB53;
      //   margin: 20px auto 0;
      // }
    }
    p{
      padding: 10px 0px 0;
      font-size: 12px;
      color: #666666;
      text-align: left;
      line-height: 18px;
    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 300px;
    }
    .carousel {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .carousel-wrapper {
      display: flex;
      width: 100%;
      justify-content:space-around;
      align-items: center;
    }
    .carousel-item {
      min-width: 300px !important;
      width: 25% !important;
      // height: 220px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      color: #fff;
      box-shadow: 0px 3px 15px 1px rgba(93,93,93,0.16);
      border-radius: 10px;
      font-size: 18px;
      padding: 30px 10px 20px;
      margin: 0 20px;
      .stars{
        img{
          width: 14px;
          height: 14px;
        }
      }
      h1{
        color: black;
        font-size: 13px;
        line-height: 25px;
        font-weight: bold;
      }
      h2{
        color: black;
        font-size: 12px;
        line-height: 18px;
        font-weight: normal;
      }
      img{
        width: 70px;
        height: 70px;
        margin-right: 20px;
      }
      p{
        margin-top: 10px;
        height: 130px;
      }
    }
    .carousel-button {
      // position: absolute;
      // padding: 20px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      color: #555;
      outline: none;
      cursor: pointer;
      img{
        width: 70px;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s, transform 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateX(200px);
    }
  }
}
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 550px;
      position: relative;
      background-size: 100% auto;
      .left_item{
        margin: 0px 10px 10px;
        // height: 500px;
        padding: 180px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 20px;
          line-height: 35px;
          // line-height: 50px;
          color: white !important;
        }
        h4{
          color: white;
          font-size: 12px;
          margin: 0 !important;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      padding: 0px calc((100% - 1200px) / 2);
      .inner2_bg{
        background: none;
      }
      div{
        width: 100%;
        padding: 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      .inner2_item{
        flex-direction: column;
      }
      .content{
        width: 100%;
        // padding: 20px 0;
        margin-bottom: 50px;
        h1{
          margin: 10px 0 20px;
          font-size: 20px;
          text-align:  center !important;
        }
        .item_info{
          margin: 20px 0 0 0 !important;
        }
      }
      .text_r{
        text-align: left !important;
        p{
          text-align: left !important;
        }
      }
      .content2{
        text-align: left;
        p{
        text-align: left;
        }
      }
    }
    .inner3{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 450px;
      position: relative;
      background: #063EA4 !important;
      margin-bottom: 50px;
      .left_item{
        // margin: 0px 10px 10px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 14px;
          line-height: 35px;
          // line-height: 50px;
          color: white !important;
          padding-left: 10px;
        }
        h4{
          color: white;
          font-size: 12px;
          margin: 0 !important;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
        .item_info{
          background: #063EA4;
          padding-left: 10px;
          margin: 30px 0;
          justify-content: center;
          .info_tips{
            p{
              font-size: 12px;
              color: white !important;
            }
            img{
              width: 15px;
            }
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner4{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      flex-direction: column;
      h1,h2,h3,p{
        text-align: center !important;
      }
      .flex{
        flex-direction: column;
      }
      .bg{
        width: 100%;
        margin: 0;
        height: 200px;
      }
      .content{
        width: 100%;
        margin: 0 0 20px 0 !important;
        img{
          margin: 0 auto;
          // width: calc(100vw - 40px) !important;
        }
        div{
          width: calc(100vw - 40px) !important;
          p{
            font-size: 12px;
          }
        }
      }
      .title{
        width: 100%;
        margin-bottom: 20px;
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner5{
      h3{
        width: 100%;
        font-size: 20px;
        text-align: center;
      }
      .inner2_item{
        flex-direction: column;
        width: 100%;
        .content{
          width: 80%;
          margin: 0 auto 30px;
        }
      }
    }
    .inner6{
      padding: 30px 20px 30px;
      position: relative;
      display: none;
      h3{
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: 320px;
      }
      .carousel-item{
        min-width: 150px !important;
        width: 45% !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        h1{
          font-size: 12px;
          padding: 0 !important;
        }
        img{
          margin-bottom: 10px;
        }
        p{
          display: none;
        }
      }
      .carousel-button{
        position: absolute;
      }
    }
  }
}
</style>
